import Vue from "vue";
import i18n from "../i18n/i18n.js";
import { DateTime } from "luxon";

Vue.filter("truncate", function (value, limit) {
  if (value.length > limit) {
    value = value.substring(0, limit - 3) + "...";
  }

  return value;
});

Vue.filter("toCents", function (value) {
  return (value / 100).toFixed(2);
});

Vue.filter("toCurrency", function (value) {
  return new Intl.NumberFormat(i18n.locale, {
    style: "currency",
    currency: "EUR",
  }).format(value);
});

Vue.filter("toDate", function (value) {
  return isNaN(DateTime.fromISO(value))
    ? DateTime.fromJSDate(value).toLocaleString()
    : DateTime.fromISO(value).toLocaleString();
});
Vue.filter("toDateTime", function (value) {
  return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT);
});
Vue.filter("toDateFromSQL", function (value) {
  return DateTime.fromSQL(value).toLocaleString();
});
Vue.filter("toDateFromJS", function (value) {
  return DateTime.fromJSDate(value).toLocaleString();
});

Vue.filter("isPast", function (value) {
  return DateTime.fromISO(value).ts < DateTime.now().ts;
});
